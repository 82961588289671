.loader {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fefefe;
  z-index: 30000;
  opacity: 0.75;

  &.loader-non-blocking {
    position: relative;
    opacity: 1;
    height: 30vh;
  }
}

.loaderimg {
  width: 100px;
  height: 100px;
  align-self: center;
  margin: 0 auto;
  margin-top: -100px;
  opacity: 0.95;
}
