.CollectionNavLink {
  .nav-group {
    display: flex !important;
    align-items: baseline;
    .nav-text {
      overflow-wrap: anywhere;
    }
  }
}

.CollectionNavLink, .CollectionItemLink {
  padding: 8px 0 8px 10px !important;
}

.CollectionItemNav {
  font-size: 95%;
  a {
    padding: 8px 0 8px 10px !important;
  }
}

.CollectionItemNavLink {
  display: flex !important;
  align-items: baseline;
  .nav-text {
    overflow-wrap: anywhere;
  }
}

.list-item {
  .card-body {
    color: #333333;
    padding-top: 0;
  }
}

.repoDir {
  width: 253px;
  margin: 18px;
  border: 2px black solid;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .fileIcon {
      width: 80px;
  }
  .opaqueBg * {
  color: #1e1f2047;
  z-index: 2;
  & h3.subtitle {
    color: #1e1f2047;
    text-decoration: none;
    }
  }
}

.inDirDropZone {
  position: absolute;
  width: 100%;
  height: 100%;
  border: #d62b00 2px dashed;
  border-color: inherit;
  background-color: #ffffffd1;
  outline: 0;
  box-shadow: 0px 0 3px 0.3rem rgba(255, 53, 0, 0.37);
}

.inFileDropZone {
  z-index: 3;
  position: absolute;
  top: 8px;
  right: 12px;
  width: 99%;
  border: #d62b00 2px dashed;
  height: 99%;
  border-color: inherit;
  background-color: #ffffffd1;
  outline: 0;
  box-shadow: 0px 0 3px 0.3rem rgba(255, 53, 0, 0.37);
}

.file-tree {
  min-width: 250px;
  padding: 37px 20px 20px 0;
}

.pagination {
  display: flex;
  list-style: none;
  justify-content: center;
  li {
    border: none;
  }
  li.active {
    border-bottom: none;
  }
  li.disabled {
    color: lightgrey;
  }
}

.Select-control {
  min-width: 200px;
  height: 40px !important;
  border-radius: 0 !important;
  border: 1px solid #c2c2c2 !important;
  &:focus, &:active {
    border-color: #d62b00 !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgba(255, 53, 0,.25) !important;
  }
  .select-input input {
    &:focus, &:active {
      background-color: rgba(255, 53, 0,.25) !important;
      outline: 0 !important;
    }
  }
}
.Select-menu-outer div{
  background-color: white;
  &.is-focused {
    background-color: rgba(255, 53, 0,.25) !important;
  }
  //background-color: rgba(255, 53, 0s,.5) !important;
  &:focus, &:active, &:hover {
    background-color: rgba(255, 53, 0,.25) !important;
    outline: 0 !important;
  }
}
.file-upload {
  width: 100%;
  max-width: 300px;
  margin: auto;
  // background-color: #f7f7f7;
  // background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3Ehoundstooth%3C/title%3E%3Cg fill='%23dedede' fill-opacity='0.35' fill-rule='evenodd'%3E%3Cpath d='M0 18h6l6-6v6h6l-6 6H0M24 18v6h-6M24 0l-6 6h-6l6-6M12 0v6L0 18v-6l6-6H0V0'/%3E%3C/g%3E%3C/svg%3E");
  background-color: #f5f5f5;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3Ehoundstooth%3C/title%3E%3Cg fill='%23d7d6d5' fill-opacity='0.29' fill-rule='evenodd'%3E%3Cpath d='M0 18h6l6-6v6h6l-6 6H0M24 18v6h-6M24 0l-6 6h-6l6-6M12 0v6L0 18v-6l6-6H0V0'/%3E%3C/g%3E%3C/svg%3E");
}

div.SingleDatePicker_picker {
  z-index: 3;
}

.inputfile{
  width:100%;
  height:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  .file-upload{
    height:100%;
    width:100%;
    // border-radius: 22px;
    position:relative;
    display:flex;
    justify-content:center;
    align-items: center;
    border:1px solid #FFFFFF;
    overflow:hidden;
    background-image: linear-gradient(to bottom, #eee 50%, #FFFFFF 50%);
    background-size: 100% 200%;
    transition: all .25s;
    color: #FFFFFF;
    font-size:100px;
    cursor: pointer;
    input[type='file']{
      height:44px;
      width:100%;
      position:absolute;
      top:0;
      left:0;
      opacity:0;
      cursor:pointer;
    }
    .fa {
      font-size: 1rem;
    }
    &:hover{
      background-position: 0 -100%;
      color: $theme-orange-color;
      border:1px solid $theme-orange-color;
      cursor: pointer;
    }
    .btn-upload {
      width: 100%;
      height: 100%;
      line-height: 1.75;
      z-index: 2;
    }
  }
}

.directory-file-upload {
  position: relative;
  input[type='file']{
      height:44px;
      width:100%;
      position:absolute;
      top:0;
      left:0;
      opacity:0;
      cursor:pointer;
    }
}

.file-select {
  background-color: rgba(150, 160, 180,.25);
  padding: 15px;
  margin: 0 0 0 2em;
  text-align: center;
  max-width: 200px;
}

div.Select-menu-outer {
  z-index: 99;
}

.select-form-group-container {
  margin-right: 15px;
}

.welcome-screen {
  background-color: #f3f3f3;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .row {
    width: 575px;
    background-color: white;
    height: 406px;
    box-shadow:  2px 5px 14px -4px rgba(0, 0, 0, 0.50);
    .column-1 {
      height: 35px;
    }
    .column-2 {
      padding-top: 33px;
    }
    .column-3 {
      margin: -46px 0px 23px;
      a:hover{
        color: transparent;
      }
    }
    button.btn{
      padding: 5px 19px;
      border: none;
      color: white;
      background-color: $theme-orange-color;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 250px;
      &:hover {
        padding: 5px 19px;
        border: none;
        color: white;
        background-color: #cc330b;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 250px;
      }
    }
  }

}

.field-group{
  margin: 5px 0;
  padding: 10px;
  //border: 1px dashed #cecece;
  .form-group {
    label {
      //width: 93%;
      width: 100%;
    }
  }
  .form-control::placeholder {
    color: #999;
  }
  .form-control {
    // height: 40px;
    color: #222;
    border-radius: 0;
    border: 1px solid #c2c2c2;
    &:focus, &:active {
      border-color: #d62b00;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(255, 53, 0,.25);
    }
  }
}
.field-group > .field-group {
  border: 1px dashed #cecece;
  .field-group {
    background: #f9f9f9;
    border: 1px dashed #dedede;
    //border: none;
    .field-group {
      border: 1px dashed #eee;
      background: #fdfdfd;
    }
  }
}
.form-check-label {}
.form-check-input {
  position: absolute;
  margin-top: .125rem;
  margin-left: 10px !important;
  zoom: 1.5;
}


div.DateInput__block {
  width: 99%;
}

.LanguageSelect {
  .dropdown-btn {
    padding-top: 0.5rem !important;
    border: none !important;
    text-transform: none;
    &:active, &:focus, &:hover {
      border: none !important;
      background: none !important;
      box-shadow: none !important;
      color: grey !important
    }
    .small {
      font-size: 0.75rem;
    }
  }
  .dropdown-menu {
    min-width: 0;
  }
  .dropdown-item {
    border: none;
    &:disabled {
      color: $theme-orange-color;
    }
    &:active, &:focus, &:hover {
      background: #eaeaea;
      color: $theme-primary-color;
      border: none !important;
      outline: none;
    }
  }
}

.NavSelect {
  text-align: left;
  white-space: nowrap;
  width: 100%;
  font-size: 0.75rem;
}