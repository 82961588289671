
.avatar {
	height: 45px;
	border-radius: 50%;
	margin: 0.75rem;
	margin-left: 0;
}

.card {
	margin: 10px;
	color: #333;
	// width: 270px;
	min-width: 100px;
	border-radius: $border-radius-base !important;
	border: 1px solid #eee;
	font-weight: 300 !important;
	.card-title, h5.card-title {
		flex: 1 1 auto;
		padding: .5rem 1rem 0 1rem;
		margin-bottom: 0;
		font-size: 1.1em;
		background-color: #f0f0f0;
		font-weight: 400;
	}
	.repo-name {
		font-weight: 400;
	}
}

.summary {
	margin-bottom: 1em;
}

.AppLastUpdated {
	margin-bottom: 0;
}


.fa-icon-sm{
  display: inline;
  margin: 6px;
}

.logo-sm {
	height: 29px;
	margin: 4px 10px;
}
.logo-md {
	height: 38px;
	margin: 15px 19px;
}
.logo-lg {
	height: 85px;
	margin: 15px 19px;
}
.logo-xl {
	height: 200px;
	margin: 15px 19px;
}

a.link,
a.link:hover {
	text-decoration: none;
}

.subtitle {
	color: black;
	font-size: 18px;
}

label span {
	font-size: 15px;
  font-weight: 300;
  color: #515151;
}
.input-group-addon {
	border: 1px lightgrey solid;
	border-right: none;
}
.cursor-pointer{
	cursor: pointer;
}

div.container-fluid {
	overflow-x: auto;

	&.CollectionItemSingle, .DocumentList {
		overflow: visible;
	}
}