.WithContent {
  margin: 50px;
}

.home-template {
  padding: 30px;
}

.collections-section {
  margin: 15px 0;
  padding: 0;
  .nav {
    flex-wrap: nowrap;
    .nav-link {
      padding: .5rem;
      @media (max-width: $narrow-width-max) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.empty-item-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content:center;
  align-items: flex-start;
  padding-top: 10%;
}

.file-tree-map-section {
  a.parent-link{
    color: black;
  }
  a.active {
    color: #ff3400;
  }
  a.not-active {
    color: #aeb3b8;
  }
  .nested-dir {
    margin-left: 19px;
    padding: 7px 0;
    position: relative;
    .dir-list-file-tree{
      padding: 0px 0;
      position: relative;
      margin-bottom: 7px;
      .nested-lines{
        width: 18px;
        height: 32px;
        border-left: 2px black solid;
        border-bottom: 2px black solid;
        position: absolute;
        top: -19px;
        left: -5px;
      }
      .staged-file-indictaor {
        position: absolute;
        width: 10px;
        top: 4px;
        left: -20px;
        border-radius: 49%;
        height: 10px;
        background-color: #ff2b00;
      }
    }
    .nested-lines{
      width: 12px;
      height: 26px;
      border-left: 2px black solid;
      border-bottom: 2px black solid;
      position: absolute;
      top: -6px;
      left: -3px;
    }
  }
}
