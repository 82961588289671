.notifications {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
  z-index: 1111;

  .alert {
    white-space: pre;
    border-radius: 0;
  }
}
