
.row {
  &.nav-tabs {
    border-bottom: none;
    .tabs-vertical {
      padding: 0;
      z-index: 1;
      .nav-item {
        background-color: #f0f0f0;
        color: #a5a5a5;
        border: 1px #c5c5c5 solid;
        display: flex;
        justify-content: flex-start;
        padding-left: 10px;
        word-break: break-word;
        font-size: .9em;
        &.active{
          border-left: 3px $theme-orange-color solid;
          border-right: none;
          color: $theme-orange-color;
          background-color: white;
        }
      }
      .nav-link{
        flex-grow: 1;
        &.active{
          background-color: white;
          color: $theme-orange-color;
          border-right: none;
          border-left: none;
          border-radius: 0;
          border-top: none;
        }
        &:hover {
          border-color: transparent;
        }
      }
    }
  }

  .tab-content-card {
    box-shadow:  2px 5px 14px -4px rgba(0, 0, 0, 0.50);
    border: 1px #c5c5c5 solid;
    padding: 16px;
    height: 100%;
    min-height: 420px;
    margin-left: -15px;
    &.empty {
      border: none;
      box-shadow: none;
    }
  }
}

.addFile {
  padding-bottom: 20px;
  max-width: 102px;
}
