.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .body-template {
    padding: 50px 32px 40px 25px;
  }
  .content-wrapper{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    background: #fefefe;
    transition: filter 0.25s ease;
  }
}
